/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

"use strict";

//1. Preload page
initPageLoader();

$(document).ready(function ($) {
  //2. Lazy loading
  const el = document.querySelectorAll("[data-lazy-load]");
  const observer = lozad(el, {
    loaded: function (el) {
      // Custom implementation on a loaded element
      el.parentNode.classList.add("loaded");
    },
  });

  observer.observe();

  initWaitingList();
});
