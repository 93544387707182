/* ==========================================================================
Waiting List
========================================================================== */

"use strict";

function initWaitingList() {
  var form = $("#waiting-list-form");
  form.submit(function (e) {
    e.preventDefault();
    if ($("#waiting-list-btn").hasClass("is-loading")) return;
    var number = $('input[name="phone"]').val();
    var formData = {
      name: $('input[name="name"]').val(),
      email: $('input[name="email"]').val(),
      app: $('input[name="app"]').val(),
    };
    if (!formData.name || !formData.email || !formData.app || !number) {
      window.alert("Please fill in all fields of the form");
      return;
    }
    number = number.replace(/[^\d+]+/g, "");
    number = number.replace(/^00/, "+");
    if (number.match(/^234/)) number = "+" + number;
    if (number.match(/^0/)) number = number.slice(1);
    if (!number.match(/^\+/)) number = "+234" + number;
    formData["phone"] = number;

    $.ajax({
      type: form.attr("method"),
      url: form.attr("action"),
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(formData),
      dataType: "json",
      encode: true,
      beforeSend: function () {
        $("#waiting-list-form :input").prop("readonly", true);
        $("#waiting-list-btn").addClass("is-loading");
      },
      complete: function () {
        $(":input", "#waiting-list-form")
          .not(":button, :submit, :reset, :hidden")
          .val("")
          .removeAttr("checked")
          .removeAttr("selected");
        $("#waiting-list-form :input").prop("readonly", false);
        // Set our complete callback, adding the .hidden class and hiding the spinner.
        $("#waiting-list-btn").removeClass("is-loading");
        window.alert("We have received your request");
      },
    }).done(function (data) {});
  });
}
